import { loadingAnimation } from 'components/Loader/styled'
import { lighten } from 'polished'
import styled from 'styled-components/macro'
import dogLoader from '../../assets/images/dog-loader.gif'
/* Loading state bubbles (animation style from: src/components/Loader/styled.tsx) */


type FetchingProps = {
  title?: string,
  paragraph?: string
}
export const Fetching = ({ title, paragraph }: FetchingProps) => <span>
   <img src={dogLoader} />
</span>
